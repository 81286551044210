var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.ValidationObserver,
    { ref: "searchEnergyLabel" },
    [
      _c(
        "v-form",
        [
          _c(
            "masonry",
            {
              class: { noBorders: true },
              attrs: { cols: { default: 1 }, gutter: 12 },
            },
            [
              _c(
                "v-container",
                { staticClass: "boxed", attrs: { fluid: "" } },
                [
                  _c("header", [_vm._v("Gegevens overnemen van EP-Online")]),
                  !_setup.propsAreProvided
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "BagId", rules: "numeric" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "ID",
                                              outlined: "",
                                              "persistent-placeholder": "",
                                              "error-messages": errors,
                                            },
                                            model: {
                                              value: _setup.bag.id,
                                              callback: function ($$v) {
                                                _vm.$set(_setup.bag, "id", $$v)
                                              },
                                              expression: "bag.id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4205673018
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto", "align-self": "center" } },
                            [_vm._v(" of ")]
                          ),
                          _c(
                            "v-col",
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Postcode",
                                  rules: "postalcode",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Postcode",
                                              outlined: "",
                                              "persistent-placeholder": "",
                                              "error-messages": errors,
                                            },
                                            model: {
                                              value: _setup.bag.postalCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.bag,
                                                  "postalCode",
                                                  $$v
                                                )
                                              },
                                              expression: "bag.postalCode",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3488965879
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  label: "Huisnummer",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                },
                                model: {
                                  value: _setup.bag.houseNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_setup.bag, "houseNumber", $$v)
                                  },
                                  expression: "bag.houseNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "hide-details": "auto",
                                  label: "Toevoeging",
                                  outlined: "",
                                  "persistent-placeholder": "",
                                },
                                model: {
                                  value: _setup.bag.houseNumberAddition,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.bag,
                                      "houseNumberAddition",
                                      $$v
                                    )
                                  },
                                  expression: "bag.houseNumberAddition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_setup.propsAreProvided
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  on: {
                                    click: function ($event) {
                                      return _setup.onSearchEnergyLabel()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-magnify")]
                                  ),
                                  _vm._v(" Zoek "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-data-table", {
                            staticClass: "bag",
                            attrs: {
                              dense: "",
                              "show-select": "",
                              "disable-sort": "",
                              "disable-pagination": "",
                              headers: _setup.headers,
                              "hide-default-footer": "",
                              items: _setup.bagOverview,
                              loading: _setup.bagLoading,
                              "item-class": _setup.itemRowBackground,
                              "item-key": "key",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `header.data-table-select`,
                                  fn: function () {
                                    return undefined
                                  },
                                  proxy: true,
                                },
                                {
                                  key: `item.data-table-select`,
                                  fn: function ({ item }) {
                                    return [
                                      item.bag.residenceId
                                        ? [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _setup.onSelectLabel(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-forward ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: `item.bag.constructionDate`,
                                  fn: function ({ item }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _setup.formatDateCustom(
                                              item.bag.constructionDate,
                                              "YYYY"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `item.bag.buildingId`,
                                  fn: function ({ item }) {
                                    return [
                                      !item.bag.residenceId
                                        ? [
                                            _vm._v(
                                              " " + _vm._s(item.bag.buildingId)
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: `item.bag.residenceId`,
                                  fn: function ({ item }) {
                                    return [
                                      item.bag.primaryAddress
                                        ? [
                                            _vm._v(
                                              " " + _vm._s(item.bag.residenceId)
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: `item.bag.go`,
                                  fn: function ({ item }) {
                                    return [
                                      !item.bag.residenceId ||
                                      item.bag.primaryAddress
                                        ? [_vm._v(" " + _vm._s(item.bag.go))]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: `item.bag.typeDescription`,
                                  fn: function ({ item }) {
                                    return [
                                      !item.bag.residenceId ||
                                      item.bag.primaryAddress
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.bag.typeDescription)
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: `item.bag.addressTypeDescription`,
                                  fn: function ({ item }) {
                                    return [
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.bag.addressTypeDescription
                                            )
                                        ),
                                      ],
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }